import {useMediaQuery} from "@react-hook/media-query";
import {StaticImage} from "gatsby-plugin-image";
import {useCallback, useState} from "react";
import * as React from "react";
import Container from "../../Layout/Container";
import TariffCard from "../Card";
import Integrations from "./Integrations";
import UsersPicker from "./UsersPicker";
import Button from "../../UI/Button";
import * as calculatorStyles from "./calculator.module.css";

const steps = {
    USERS_COUNT: {
        index: 0,
        name: "usersCount",
        proceed(value) {
            if (value <= 0) {
                return "USERS_COUNT";
            }

            return "INTEGRATIONS";
        },
    },
    INTEGRATIONS: {
        index: 1,
        name: "integrations",
        proceed(value) {
            if (!value) {
                return "USERS_COUNT";
            }

            return "FINAL";
        },
    },
    FINAL: {
        index: 2,
        name: "final",
        proceed() {
            return "USERS_COUNT";
        },
    },
};

const defaultData = {
    usersCount: 0,
    integrations: [],
};

const TariffCalculator = ({data, type}) => {
    const isMobile = useMediaQuery("(max-width: 900px)");

    const [chosenData, setChosenData] = useState(defaultData);
    const [currentStep, setStep] = useState(steps.USERS_COUNT);
    const [calculatedTariff, setCalculatedTariff] = useState(null);

    const calculateTariff = useCallback(
        ({usersCount, integrations}) => {
            const calculationType = data.strapi_component.indexOf("price") !== -1 ? "price" : "tariff";
            const tariffs = calculationType === "tariff" ? data.results : data.variants;

            const filteredTariffs = tariffs.filter((tariff) => {
                const tariffIntegrations = tariff.integrations.map(({name}) => name);

                const haveNeededIntegrations = integrations.every((integration) =>
                    tariffIntegrations.includes(integration),
                );

                if (calculationType !== "tariff" || !haveNeededIntegrations) {
                    return haveNeededIntegrations;
                }

                return tariff.maxUsersCount === 0 || tariff.maxUsersCount >= usersCount;
            });

            setCalculatedTariff(() => {
                if (calculationType === "tariff") {
                    return filteredTariffs[0];
                }

                return filteredTariffs
                    .map((tariff) => {
                        const additionalLicensesCount =
                            usersCount > tariff.licenseCount ? usersCount - tariff.licenseCount : 0;

                        const integrationsPrice = tariff.integrations.reduce((acc, {name, price}) => {
                            if (!integrations.includes(name)) {
                                return acc;
                            }

                            return acc + price;
                        }, 0);

                        return {
                            price:
                                tariff.price +
                                additionalLicensesCount * tariff.additionalLicensePrice +
                                integrationsPrice,
                            tariff,
                        };
                    })
                    .sort((a, b) => a.price - b.price)[0].tariff;
            });
        },
        [data],
    );

    const handleSubmit = useCallback(
        (submitData) => {
            const value = submitData[currentStep.name];

            const newChosenData = {...chosenData, [currentStep.name]: value};
            setChosenData(newChosenData);

            const nextStep = currentStep.proceed(value);

            if (nextStep === "FINAL") {
                calculateTariff(newChosenData);
            }

            setStep(steps[nextStep]);
        },
        [chosenData, currentStep, calculateTariff],
    );

    const reset = useCallback(() => {
        setStep(steps[currentStep.proceed()]);
        setChosenData(defaultData);
        setCalculatedTariff(null);
    }, [currentStep]);

    return (
        <Container>
            <div className={calculatorStyles.calculatorWrapper}>
                {type === "support" && (
                    <>
                        <StaticImage
                            src="../../../assets/images/tariffs/support/calculator/desktop.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            className={`${calculatorStyles.calculator__blur} ${calculatorStyles.calculator__blur_desktop}`}
                        />
                        <StaticImage
                            src="../../../assets/images/tariffs/support/calculator/tablet.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            className={`${calculatorStyles.calculator__blur} ${calculatorStyles.calculator__blur_tablet}`}
                        />
                        <StaticImage
                            src="../../../assets/images/tariffs/support/calculator/mobile.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            className={`${calculatorStyles.calculator__blur} ${calculatorStyles.calculator__blur_mobile}`}
                        />
                    </>
                )}
                {type === "customer" && (
                    <>
                        <StaticImage
                            src="../../../assets/images/tariffs/customer/calculator/desktop.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            className={`${calculatorStyles.calculator__blur} ${calculatorStyles.calculator__blur_desktop}`}
                        />
                        <StaticImage
                            src="../../../assets/images/tariffs/customer/calculator/tablet.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            className={`${calculatorStyles.calculator__blur} ${calculatorStyles.calculator__blur_tablet}`}
                        />
                        <StaticImage
                            src="../../../assets/images/tariffs/customer/calculator/mobile.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            className={`${calculatorStyles.calculator__blur} ${calculatorStyles.calculator__blur_mobile}`}
                        />
                    </>
                )}
                {type === "outsource" && (
                    <>
                        <StaticImage
                            src="../../../assets/images/tariffs/outsource/calculator/desktop.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            className={`${calculatorStyles.calculator__blur} ${calculatorStyles.calculator__blur_desktop}`}
                        />
                        <StaticImage
                            src="../../../assets/images/tariffs/outsource/calculator/tablet.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            className={`${calculatorStyles.calculator__blur} ${calculatorStyles.calculator__blur_tablet}`}
                        />
                        <StaticImage
                            src="../../../assets/images/tariffs/outsource/calculator/mobile.png"
                            alt=""
                            layout="fixed"
                            placeholder="none"
                            className={`${calculatorStyles.calculator__blur} ${calculatorStyles.calculator__blur_mobile}`}
                        />
                    </>
                )}
                <div className={calculatorStyles.calculator}>
                    <div className={calculatorStyles.calculatorBackdrop} />
                    <div className={calculatorStyles.calculator__panelWrapper} style={{"--step": currentStep.index}}>
                        <div className={calculatorStyles.calculator__panel}>
                            <h3 className={calculatorStyles.calculator__title}>Калькулятор тарифов</h3>
                            <p className={calculatorStyles.calculator__stepIndicator}>Шаг 1 из 3</p>
                            <h4 className={calculatorStyles.calculator__panelTitle}>
                                Укажите количество специалистов которые будут принимать непосредственное участие в
                                обработке поступающих заявок
                            </h4>
                            <UsersPicker
                                maxUsersCount={data.maxUsersCount}
                                id={steps.USERS_COUNT.name}
                                onSubmit={handleSubmit}
                            />
                            <Button
                                type="submit"
                                variant="primary"
                                className={calculatorStyles.calculator__button}
                                form={steps.USERS_COUNT.name}
                            >
                                Далее
                            </Button>
                        </div>
                        <div className={calculatorStyles.calculator__panel}>
                            <h3 className={calculatorStyles.calculator__title}>Калькулятор тарифов</h3>
                            <p className={calculatorStyles.calculator__stepIndicator}>Шаг 2 из 3</p>
                            <h4 className={calculatorStyles.calculator__panelTitle}>
                                Интеграция с какими системами вам необходима?
                            </h4>
                            <Integrations
                                integrations={data.integrations}
                                id={steps.INTEGRATIONS.name}
                                onSubmit={handleSubmit}
                            />
                            <Button
                                type="submit"
                                variant="primary"
                                className={calculatorStyles.calculator__button}
                                form={steps.INTEGRATIONS.name}
                            >
                                Далее
                            </Button>
                        </div>
                        <div
                            className={`${calculatorStyles.calculator__panel} ${calculatorStyles.calculator__panel_final}`}
                        >
                            <div>
                                <h4
                                    className={`${calculatorStyles.calculator__panelTitle} ${calculatorStyles.calculator__panelTitle_big}`}
                                >
                                    <span>Оптимальный</span> тариф для вашего бизнеса
                                </h4>
                                {!isMobile && (
                                    <Button
                                        variant="default"
                                        className={calculatorStyles.calculator__button}
                                        onClick={reset}
                                    >
                                        Новый расчет
                                    </Button>
                                )}
                            </div>
                            {calculatedTariff && <TariffCard tariff={calculatedTariff.tariff} />}
                            {isMobile && (
                                <Button
                                    variant="default"
                                    className={calculatorStyles.calculator__button}
                                    onClick={reset}
                                >
                                    Новый расчет
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default TariffCalculator;
