import {useCallback} from "react";
import cx from "classnames";
import * as React from "react";
import {formatNumber, pluralize} from "../../utils/number";
import Button from "../UI/Button";
import FireIcon from "../../assets/svg/fire.svg";
import * as cardStyles from "./card.module.css";

const TariffCard = ({tariff}) => {
    const scrollToForm = useCallback(() => {
        const form = document.querySelector("#free-demo-form");
        if (!form) {
            return;
        }

        form.scrollIntoView({behavior: "smooth"});
    }, []);

    return (
        <div
            className={cx(cardStyles.tariffCard, {
                [cardStyles.tariffCard_popular]: tariff.isPopular,
            })}
        >
            {tariff.discount && tariff.discount.enabled && (
                <div className={cx(cardStyles.tariffCard__badge, cardStyles.tariffCard__badge_discount)}>
                    <div>
                        <span>{tariff.discount.text}</span>
                    </div>
                </div>
            )}
            {tariff.isPopular && (
                <div className={cx(cardStyles.tariffCard__badge, cardStyles.tariffCard__badge_popular)}>
                    <span>Популярно</span>
                    <FireIcon />
                </div>
            )}
            <div>
                <h2 className={cardStyles.tariffCard__title}>{tariff.title}</h2>
                <p className={cardStyles.tariffCard__price}>{formatNumber(tariff.price)}</p>
                <p className={cardStyles.tariffCard__priceNote}>рублей в месяц</p>
                <p className={cardStyles.tariffCard__desc}>{tariff.description}</p>
            </div>
            <div>
                <p className={cardStyles.tariffCard__licenseCount}>
                    {pluralize(tariff.freeLicenseCount, ["%d лицензия", "%d лицензии", "%d лицензий"])}
                </p>
                <Button
                    variant="primary"
                    type="button"
                    className={cardStyles.tariffCard__button}
                    onClick={scrollToForm}
                >
                    Попробовать
                </Button>
            </div>
            <div className={cardStyles.tariffCard__backdrop} />
        </div>
    );
};

export default TariffCard;
