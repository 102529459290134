// extracted by mini-css-extract-plugin
export var table = "table-module--table--UJ1Zw";
export var table__header = "table-module--table__header--jFPe9";
export var table__header_content = "table-module--table__header_content--16rCY";
export var table__subheader = "table-module--table__subheader--ErOX1";
export var table__row = "table-module--table__row--1EEjY";
export var table__row_content = "table-module--table__row_content--GOxYM";
export var table__cell = "table-module--table__cell--HKWp4";
export var table__cellIcon = "table-module--table__cell-icon--7xIyB";
export var table__cellIcon_opened = "table-module--table__cell-icon_opened--nrlf7";
export var table__cell_heading = "table-module--table__cell_heading--C48iu";
export var table__cell_heading_content = "table-module--table__cell_heading_content--ac66C";
export var table__cell_center = "table-module--table__cell_center--Z96mf";
export var table__attribute = "table-module--table__attribute--siJGO";
export var table__attributeContent = "table-module--table__attribute-content--4w753";
export var table__attributeIcon = "table-module--table__attribute-icon--+8mlf";
export var table_scrolled = "table-module--table_scrolled--tr7a0";
export var table__rightColumn = "table-module--table__right-column--sInnD";
export var table__rightColumnWrapper = "table-module--table__right-column-wrapper--tbWJ8";