import * as React from "react";
import SectionTitle from "../../Layout/Section/Title";
import Container from "../../Layout/Container";
import CompareTable from "./Table";

const Compare = ({attributeGroups: rawAttributesGroups, tariffs}) => {
    const attributesWithValues = new Set();

    const attributeValues = tariffs.reduce(
        (acc, {id: tariffId, title, tariffAttributes}, tariffIdx) => {
            acc.tariffs.push({id: tariffId, title});

            let haveAttributesForTariff = false;

            tariffAttributes.forEach(({id, description, isAvailable, note, attribute}) => {
                if (!attribute) {
                    return;
                }

                if (!acc.groups[attribute.group]) {
                    acc.groups[attribute.group] = {};
                }

                if (!acc.groups[attribute.group][attribute.id]) {
                    acc.groups[attribute.group][attribute.id] = Array.from({length: tariffs.length}, () => undefined);
                }

                haveAttributesForTariff = true;

                attributesWithValues.add(attribute.id);

                acc.groups[attribute.group][attribute.id][tariffIdx] = {
                    id,
                    description,
                    isAvailable,
                    note,
                    group: attribute.group,
                };
            });

            if (!haveAttributesForTariff) {
                acc.tariffs[tariffIdx] = undefined;
            }

            return acc;
        },
        {tariffs: [], groups: {}},
    );

    const attributeGroups = rawAttributesGroups.edges
        .map(({node: {id, hint, showTitle, title, ownedAttributes}}) => {
            const haveOnlyEmptyAttributes = ownedAttributes.every(
                ({id: attributeId}) => attributesWithValues.size > 0 && !attributesWithValues.has(attributeId),
            );
            if (haveOnlyEmptyAttributes) {
                return false;
            }

            const attributes = ownedAttributes
                .sort((a, b) => a.id - b.id)
                .map(({id: attributeId, title: attributeTitle, hint: attributeHint, isImportant}) => {
                    if (attributesWithValues.size > 0 && !attributesWithValues.has(attributeId)) {
                        return false;
                    }

                    return {
                        title: attributeTitle,
                        hint: attributeHint,
                        isImportant,
                        id: attributeId,
                    };
                })
                .filter(Boolean);

            return {
                id,
                hint,
                showTitle,
                title,
                attributes,
            };
        })
        .filter(Boolean);

    if (attributesWithValues.size === 0) {
        return null;
    }

    return (
        <Container>
            <SectionTitle>Подробное сравнение тарифов</SectionTitle>
            <CompareTable groups={attributeGroups} values={attributeValues} />
        </Container>
    );
};

export default Compare;
