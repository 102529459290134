// extracted by mini-css-extract-plugin
export var calculator = "calculator-module--calculator--QsPQn";
export var calculatorWrapper = "calculator-module--calculator-wrapper--6gF1U";
export var calculatorBackdrop = "calculator-module--calculator-backdrop--y2mg9";
export var calculator__title = "calculator-module--calculator__title--oB26l";
export var calculator__stepIndicator = "calculator-module--calculator__step-indicator--1RNya";
export var calculator__button = "calculator-module--calculator__button--+1BaF";
export var calculator__panel = "calculator-module--calculator__panel--Iyh9Z";
export var calculator__panel_final = "calculator-module--calculator__panel_final--tcB5+";
export var calculator__panelTitle = "calculator-module--calculator__panel-title--1sryV";
export var calculator__panelTitle_big = "calculator-module--calculator__panel-title_big--rKTUU";
export var calculator__panelWrapper = "calculator-module--calculator__panel-wrapper--STpwW";
export var calculator__blur = "calculator-module--calculator__blur--dO0yp";
export var calculator__blur_desktop = "calculator-module--calculator__blur_desktop--MRlMQ";
export var calculator__blur_mobile = "calculator-module--calculator__blur_mobile--HByev";
export var calculator__blur_tablet = "calculator-module--calculator__blur_tablet--2C4a2";