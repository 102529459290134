import classNames from "classnames/bind";
import {useCallback, useEffect, useRef, useState} from "react";
import * as React from "react";
import Check from "../../../assets/svg/tariffs/check.svg";
import Cross from "../../../assets/svg/tariffs/cross.svg";
import Note from "../../../assets/svg/tariffs/note.svg";
import Popover from "../../Common/Popover";
import * as tableStyles from "./table.module.css";

const cx = classNames.bind(tableStyles);

const CompareTable = ({groups, values}) => {
    const leftColumn = useRef(null);
    const rightColumn = useRef(null);

    const [openedPopoverId, setOpenedPopoverId] = useState("");
    const [tableWasScrolled, setTableScrolledState] = useState(false);

    const handleScroll = useCallback(({target}) => {
        const {scrollLeft} = target;

        setTableScrolledState((wasScrolled) => {
            if ((wasScrolled && scrollLeft > 0) || (!wasScrolled && scrollLeft <= 0)) {
                return wasScrolled;
            }

            return scrollLeft > 0;
        });
    }, []);

    const getAttributeCell = useCallback((attribute, idx) => {
        const getAttributeCellContent = (attributeValue) => {
            if (!attributeValue.isAvailable) {
                return <Cross />;
            }

            if (!attributeValue.description) {
                return <Check className={tableStyles.table__attributeIcon} />;
            }

            return (
                <p className={tableStyles.table__attributeContent}>
                    {attributeValue.description} {attributeValue.note && <span>{attributeValue.note}</span>}
                </p>
            );
        };

        return (
            <div key={attribute?.id ?? idx} className={cx({table__cell: true, table__cell_center: true})}>
                {attribute && <div className={tableStyles.table__attribute}>{getAttributeCellContent(attribute)}</div>}
            </div>
        );
    }, []);

    const getAttributeValues = useCallback(
        ({tariffs, groups: attributeGroups}) => {
            const tariffsWithAttributesCount = tariffs.filter(Boolean).length;

            return (
                <>
                    <div
                        className={cx({table__header: true, table__header_content: true})}
                        style={{"--count": tariffsWithAttributesCount}}
                    >
                        {tariffs.map((tariff) => {
                            if (!tariff) {
                                return null;
                            }

                            const {id: tariffId, title} = tariff;

                            return (
                                <div
                                    key={tariffId}
                                    className={cx({
                                        table__cell: true,
                                        table__cell_heading: true,
                                        table__cell_heading_content: true,
                                        table__cell_center: true,
                                    })}
                                >
                                    <p>{title}</p>
                                </div>
                            );
                        })}
                    </div>
                    {groups.map(({id}) => {
                        return (
                            <React.Fragment key={id}>
                                <div className={tableStyles.table__subheader} />
                                {Object.entries(attributeGroups[id]).map(([attributeId, attributeValues]) => {
                                    return (
                                        <div
                                            key={attributeId}
                                            className={cx({table__row: true, table__row_content: true})}
                                            style={{"--count": tariffsWithAttributesCount}}
                                        >
                                            {attributeValues.map((attribute, idx) => {
                                                if (!tariffs[idx]) {
                                                    return null;
                                                }

                                                return getAttributeCell(attribute, idx);
                                            })}
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </>
            );
        },
        [getAttributeCell, groups],
    );

    const getAttribute = useCallback(
        ({id, title, hint}) => {
            return (
                <div key={id} className={tableStyles.table__row}>
                    <div className={`${tableStyles.table__cell}`}>
                        <div className={cx({table__cellIcon: true, table__cellIcon_opened: openedPopoverId === id})}>
                            {hint && (
                                <Popover
                                    content={hint}
                                    onOpen={() => setOpenedPopoverId(id)}
                                    onClose={() => setOpenedPopoverId("")}
                                >
                                    <Note />
                                </Popover>
                            )}
                        </div>
                        <p>{title}</p>
                    </div>
                </div>
            );
        },
        [openedPopoverId],
    );

    const getAttributeGroup = useCallback(
        ({id, title, showTitle, attributes}) => {
            return (
                <React.Fragment key={id}>
                    <div className={tableStyles.table__subheader}>
                        {showTitle && (
                            <div className={cx({table__cell: true, table__cell_heading: true})}>
                                <div className={tableStyles.table__cellIcon} />
                                <p>{title}</p>
                            </div>
                        )}
                    </div>
                    {attributes.map(getAttribute)}
                </React.Fragment>
            );
        },
        [getAttribute],
    );

    const alignRowHeights = useCallback(() => {
        if (!leftColumn.current || !rightColumn.current) {
            return;
        }

        const leftColumnRows = leftColumn.current.children;
        const rightColumnRows = rightColumn.current.children;

        [...leftColumnRows].forEach((leftColRow, idx) => {
            const rightColRow = rightColumnRows[idx];

            leftColRow.style.setProperty("height", "auto");
            rightColRow.style.setProperty("height", "auto");
        });

        // eslint-disable-next-line no-unused-expressions
        window.innerHeight;

        [...leftColumnRows].forEach((leftColRow, idx) => {
            const rightColRow = rightColumnRows[idx];

            const leftRowHeight = leftColRow.clientHeight;
            const rightRowHeight = rightColRow.clientHeight;

            const maxHeight = Math.max(leftRowHeight, rightRowHeight);

            leftColRow.style.setProperty("height", `${maxHeight}px`);
            rightColRow.style.setProperty("height", `${maxHeight}px`);
        });
    }, [leftColumn, rightColumn]);

    useEffect(() => {
        alignRowHeights();
    }, [alignRowHeights, groups, values]);

    useEffect(() => {
        const resizeListener = (() => {
            let timeout;

            return () => {
                if (timeout) {
                    window.clearTimeout(timeout);
                    timeout = undefined;
                }

                timeout = setTimeout(() => {
                    timeout = undefined;

                    alignRowHeights();
                }, 200);
            };
        })();

        window.addEventListener("resize", resizeListener, {passive: true});

        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, [alignRowHeights]);

    return (
        <div className={cx({table: true, table_scrolled: tableWasScrolled})}>
            <div ref={leftColumn}>
                <div className={tableStyles.table__header}>
                    <div className={cx({table__cell: true, table__cell_heading: true})}>
                        <div className={tableStyles.table__cellIcon} />
                        <p>ТАРИФЫ</p>
                    </div>
                </div>
                {groups.map(getAttributeGroup)}
            </div>
            <div className={tableStyles.table__rightColumnWrapper} onScroll={handleScroll}>
                <div ref={rightColumn} className={tableStyles.table__rightColumn}>
                    {getAttributeValues(values)}
                </div>
            </div>
        </div>
    );
};

export default CompareTable;
