import PropTypes from "prop-types";
import {useCallback, useEffect, useMemo, useState} from "react";
import * as React from "react";
import {Popover as TinyPopover} from "react-tiny-popover";
import * as popoverStyles from "../Tariffs/Compare/popover.module.css";

const Popover = ({content, children, onOpen, onClose, padding}) => {
    const [isOpened, setOpened] = useState(false);

    const popoverContent = useMemo(() => {
        return (
            <div className={popoverStyles.popover}>
                <span>{content}</span>
            </div>
        );
    }, [content]);

    const openPopover = useCallback(() => {
        if (isOpened) {
            return;
        }

        if (typeof onOpen === "function") {
            onOpen();
        }

        setOpened(true);
    }, [isOpened, onOpen]);

    const closePopover = useCallback(() => {
        if (!isOpened) {
            return;
        }

        if (typeof onClose === "function") {
            onClose();
        }

        setOpened(false);
    }, [isOpened, onClose]);

    const togglePopover = useCallback(() => {
        if (isOpened) {
            closePopover();
        } else {
            openPopover();
        }
    }, [isOpened, closePopover, openPopover]);

    useEffect(() => {
        const scrollListener = (() => {
            let isFrameRequested = false;

            return () => {
                if (isFrameRequested) {
                    return;
                }

                isFrameRequested = true;
                requestAnimationFrame(() => {
                    isFrameRequested = false;

                    closePopover();
                });
            };
        })();

        document.addEventListener("scroll", scrollListener);

        return () => {
            document.removeEventListener("scroll", scrollListener);
        };
    }, [closePopover]);

    return (
        <TinyPopover
            isOpen={isOpened}
            onClickOutside={closePopover}
            padding={padding}
            positions={["top", "bottom"]}
            align="start"
            containerClassName={popoverStyles.popover__wrapper}
            content={popoverContent}
        >
            <button type="button" onClick={togglePopover} onMouseEnter={openPopover} onMouseLeave={closePopover}>
                {children}
            </button>
        </TinyPopover>
    );
};

Popover.defaultProps = {
    padding: 6,
};

Popover.propTypes = {
    padding: PropTypes.number,
};

export default Popover;
