// extracted by mini-css-extract-plugin
export var tariffHero = "hero-module--tariff-hero--6XCOJ";
export var tariffHero__title = "hero-module--tariff-hero__title--PTai9";
export var tariffHero__desc = "hero-module--tariff-hero__desc--8xBrK";
export var tariffHero__product_name = "hero-module--tariff-hero__product_name--fZFsw";
export var tariffHero__product_text = "hero-module--tariff-hero__product_text--1b9DT";
export var tariffHero__tariffList = "hero-module--tariff-hero__tariff-list--NyUAD";
export var tariffHero__blur = "hero-module--tariff-hero__blur--OoNY4";
export var tariffHero__blur_tablet = "hero-module--tariff-hero__blur_tablet--bRCIG";
export var tariffHero__blur_mobile = "hero-module--tariff-hero__blur_mobile--Jzu6m";
export var tariffHero__blur_desktop = "hero-module--tariff-hero__blur_desktop--tW1YB";