import classNames from "classnames";
import * as React from "react";
import {ProductJsonLd} from "gatsby-plugin-next-seo";
import Layout from "../components/Layout/layout";
import Seo from "../components/SEO/SEO";
import TariffHero from "../components/Tariffs/Hero";
import Compare from "../components/Tariffs/Compare";
import TariffCalculator from "../components/Tariffs/Calculator";
import FreeDemoForm from "../components/Form/FreeDemo";
import * as pageStyles from "./tariff-page.module.css";
import TariffBanner from "../components/Tariffs/Banner";

const TariffPage = ({
    pageContext: {
        pageData: {product, attributeGroups},
    },
    location,
}) => {
    const tariffs = product.tariffs.sort(({price: aPrice}, {price: bPrice}) => aPrice - bPrice);

    const offers = product.tariffs.map((tariff) => {
        return {
            price: tariff.price,
            priceCurrency: "RUB",
            itemCondition: "https://schema.org/UsedCondition",
            availability: "https://schema.org/InStock",
            url: location.href,
            seller: {
                name: tariff.title,
            },
        };
    });

    return (
        <Layout className={classNames([pageStyles.tariffPage, pageStyles[`tariffPage_${product.type}`]])}>
            <Seo seo={product.seo} />
            <ProductJsonLd name={product.title} description={product.description} brand="ITSM 365" offers={offers} />
            <TariffHero
                type={product.type}
                slug={product.slug}
                freeDays={product.freeDaysCount}
                tariffs={tariffs}
                title={product.tariffsTitle || ""}
            />
            {product.tarrifsBanner && <TariffBanner banners={product.tarrifsBanner} />}
            <Compare attributeGroups={attributeGroups} tariffs={tariffs} />
            {product.calculator.length > 0 && <TariffCalculator data={product.calculator[0]} type={product.type} />}
            <FreeDemoForm />
        </Layout>
    );
};

export default TariffPage;
