import * as React from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {Checkbox} from "../../Form/Fields/Checkbox";
import * as integrationsStyles from "./integrations.module.css";

const Integrations = ({integrations, onSubmit, id}) => {
    const {handleSubmit, control, register} = useForm({
        defaultValues: {
            integrations: integrations.map((integration) => ({
                id: integration.id,
                name: integration.name,
                selected: false,
            })),
        },
    });

    const {fields} = useFieldArray({
        control,
        name: "integrations",
    });

    const filterValues = ({integrations: chosenIntegrations}) => {
        onSubmit({integrations: chosenIntegrations.filter(({selected}) => selected).map(({name}) => name)});
    };

    return (
        <form onSubmit={handleSubmit(filterValues)} id={id} className={integrationsStyles.integrations}>
            {fields.map(({name, id: integrationId}, idx) => {
                return <Checkbox key={integrationId} label={name} {...register(`integrations.${idx}.selected`)} />;
            })}
        </form>
    );
};

export default Integrations;
