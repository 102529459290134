import {useCallback, useEffect, useState} from "react";
import * as React from "react";
import {useForm} from "react-hook-form";
import {Range} from "react-range";
import * as pickerStyles from "./usersPicker.module.css";

const UsersPicker = ({maxUsersCount, onSubmit, id}) => {
    const {register, handleSubmit, setValue} = useForm({
        defaultValues: {
            usersCount: 1,
        },
    });

    const [usersCount, setUsersCount] = useState([1]);

    const updateUsersCount = useCallback(
        (values) => {
            setUsersCount(values);
            setValue("usersCount", values[0]);
        },
        [setValue],
    );

    useEffect(() => {
        register("usersCount");
    }, [register]);

    return (
        <Range
            step={1}
            min={1}
            max={maxUsersCount}
            values={usersCount}
            onChange={updateUsersCount}
            renderTrack={({props, children}) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <form
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={props.style}
                    className={pickerStyles.usersPicker}
                    id={id}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <p className={pickerStyles.usersPicker__label}>
                        <span>{usersCount[0] > 1 && usersCount[0] - 1}</span>
                        <span>{usersCount[0] === maxUsersCount ? `${usersCount[0]}+` : usersCount[0]}</span>
                        <span>{usersCount[0] < maxUsersCount && usersCount[0] + 1}</span>
                    </p>
                    <div
                        ref={props.ref}
                        className={pickerStyles.usersPicker__track}
                        style={{
                            "--progress": `${((usersCount[0] - 1) / (maxUsersCount - 1)) * 100}%`,
                        }}
                    >
                        <div className={pickerStyles.usersPicker__trackPart} />
                        {children}
                        <div className={pickerStyles.usersPicker__trackPart} />
                    </div>
                </form>
            )}
            renderThumb={({props}) => (
                <div {...props} className={pickerStyles.usersPicker__thumb}>
                    <span />
                </div>
            )}
        />
    );
};

export default UsersPicker;
