import * as React from "react";
import cx from "classnames";
import Section from "../Layout/Section";
import Media from "../Common/Media";
import * as styles from "./banner.module.css";

const TariffBanner = ({banners}) => {
    if (!banners.desktop && !banners.laptop && !banners.mobile) {
        return null;
    }

    return (
        <Section>
            {banners.desktop && (
                <Media
                    media={{content: banners.desktop, youtubeVideoURL: ""}}
                    className={cx(styles.banner, styles.banner_desktop)}
                />
            )}
            {banners.laptop && (
                <Media
                    media={{content: banners.laptop, youtubeVideoURL: ""}}
                    className={cx(styles.banner, styles.banner_laptop)}
                />
            )}
            {banners.mobile && (
                <Media
                    media={{content: banners.mobile, youtubeVideoURL: ""}}
                    className={cx(styles.banner, styles.banner_mobile)}
                />
            )}
        </Section>
    );
};

export default TariffBanner;
