// extracted by mini-css-extract-plugin
export var tariffCard = "card-module--tariff-card--XkgQC";
export var tariffCard__backdrop = "card-module--tariff-card__backdrop--BXSU1";
export var tariffCard__title = "card-module--tariff-card__title--SedS5";
export var tariffCard__price = "card-module--tariff-card__price--ui6qm";
export var tariffCard__priceNote = "card-module--tariff-card__price-note--BO5QX";
export var tariffCard__desc = "card-module--tariff-card__desc--5x52b";
export var tariffCard__licenseCount = "card-module--tariff-card__license-count--BBdHE";
export var tariffCard__button = "card-module--tariff-card__button--tMQZj";
export var tariffCard__badge = "card-module--tariff-card__badge--CP9fW";
export var tariffCard__badge_popular = "card-module--tariff-card__badge_popular--Brci5";
export var tariffCard__badge_discount = "card-module--tariff-card__badge_discount--lvrhz";
export var tariffCard_popular = "card-module--tariff-card_popular---SgeN";